body {
    background-color: #fff;
    min-height: 100vh; }
  
  .logo {
      height: 150px; }
  
  @media (max-width: 575px) {
    .logo {
      height: 200px;
      margin-top: 50px;      
    }
  }

.caja-login-fondo{
  background-color: #d8d8d8;
  background-size: cover;
  height: 1000px;
  }

  .contenedor-caja-login{
    width: 400px;
    height: 350px;
    background-image: url('https://www.institutopetitdemeurville.com.ar/img/vista-aula-escuela.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .cuerpo-login{
    position: relative;
    bottom: -100px;
    left:0;
  }
  .cuerpo-login-circulo{
    position:absolute;
    left:125px;
    top: -25px;
    z-index: 1000;
    background-color: #fff;
    height: 150px;
    width: 150px;
    border-radius: 50%;
  }

  .cuerpo-login-trapecio {
    height: 100px;
    background-color: #fff; /* Color del trapecio */
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%, 0% 100%);
  }
  .cuerpo-login-cuadrado{
    background-color: #fff;
    padding:40px
  }



  .login-section-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 68px 100px;
    background-color: #fff; }
    @media (max-width: 991px) {
      .login-section-wrapper {
        padding-left: 50px;
        padding-right: 50px; } }
    @media (max-width: 575px) {
      .login-section-wrapper {
        padding-top: 20px;
        padding-bottom: 20px;
        min-height: 100vh; } }
  
  .login-wrapper {
    width: 300px;
    max-width: 100%;
    padding-top: 24px;
    padding-bottom: 24px; }
    @media (max-width: 575px) {
      .login-wrapper {
        width: 100%; } }
    .login-wrapper label {
      font-size: 14px;
      font-weight: bold;
      color: #b0adad; }
    .login-wrapper .form-control {
      border: none;
      border-bottom: 1px solid #e7e7e7;
      border-radius: 0;
      padding: 9px 5px;
      min-height: 30px;
      font-size: 15px;
      font-weight: normal; }
      .login-wrapper .form-control::-webkit-input-placeholder {
        color: #b0adad; }
      .login-wrapper .form-control::-moz-placeholder {
        color: #b0adad; }
      .login-wrapper .form-control:-ms-input-placeholder {
        color: #b0adad; }
      .login-wrapper .form-control::-ms-input-placeholder {
        color: #b0adad; }
      .login-wrapper .form-control::placeholder {
        color: #b0adad; }
    .login-wrapper .login-btn {
      padding: 12px 20px;
      background-color: #d62c31;
      border: 1px solid #35567E;
      width: 100%;
      border-radius: 0;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 14px; }
      .login-wrapper .login-btn:hover {
        border: 1px solid #d62c31;
        background-color: #fff;
        color: #d62c31; }
        .login-wrapper .login-btn:active {
          border: 1px solid #d62c31;
          background-color: #35567E;
          color: #fff; }
    .login-wrapper a.forgot-password-link {
      color: #080808;
      font-size: 14px;
      text-decoration: underline;
      display: inline-block;
      margin-bottom: 54px; }
      @media (max-width: 575px) {
        .login-wrapper a.forgot-password-link {
          margin-bottom: 16px; } }
    .login-wrapper-footer-text {
      font-size: 16px;
      color: #000;
      margin-bottom: 0; }
  
  .login-title {
    font-size: 25px;
    color: #000;
    font-weight: bold;
    margin-bottom: 25px; }
  
  .login-img {
    width: 100%;
    height: 100vh;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: left;
       object-position: left; }
  
  .error{
      font-size:12px;
      color:#d62c31;
      font-weight: bold;
   
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
  .fuente{
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #838383;
  }


  .input-icon-group {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    padding: 5px;
}

.icon-wrapper {
    background-color: transparent;
    border: none;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.icon-style {
    font-size: 18px;
    color: #838383; /* Mismo tono que .fuente */
    transition: color 0.3s ease;
}

.icon-style:hover {
    color: #d62c31; /* Resalta el ícono al pasar el cursor */
}

.input-group .form-control {
    border: none;
    font-size: 15px;
    color: #838383;
}

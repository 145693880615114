.container-principal{
  padding-top:6rem;
  padding-left: 6rem;
  padding-right: 1.5rem;
}
/* Estilos para teléfonos móviles */
@media (max-width: 991px) {
  .container-principal{
    padding-top:4.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
@media (max-width: 991px) {
  .visible-xs {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
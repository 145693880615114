.caja{
    position:relative;
    border: 2px solid #999;
    margin:15px;
    padding:10px;
    border-radius: 15px 0 0 15px;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    width: 18rem;
}
.caja-circulo-logo{
    position:absolute;
    left:-20px;
    top:-20px;
    width:50px;
    height: 50px;
    border: 2px solid #999;
    border-radius: 50%;
    background-color: rgb(44, 103, 180);
    color:#fff;
}
.caja-circulo-logo i{
    padding-top: 12px;
    padding-left: 12px;
    font-size: 20px;
}
.caja-titulo{
    margin-left: 20px;
    color:#666;
}
.caja-cantidades{
    margin-left: 20px;
    color:#666;
}
.caja-cantidades span{
    font-size: 20px;
    font-weight: bold;
}
.caja-botonera{
    margin: auto;
    color:#666;
}
.caja-info{
    width: 100%;
    color:#666;
    font-size: 10px;
    font-weight: bold;
}
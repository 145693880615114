
#hero .icon-boxes {
    margin-top:10px;
    
  }
  
  #hero .icon-box {
    padding: 50px 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(52, 60, 96, 0.08);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    border: 1px solid #e61d27;
    z-index: 1;
    width: 12rem;
  }
  
  #hero .icon-box .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  
    color: #e61d27;
    transition: 0.3s;
    text-decoration: none;
  }
  
  #hero .icon-box .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  #hero .icon-box .icon {
    margin-bottom: 20px;
    padding-top: 10px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    font-size: 36px;
    line-height: 1;
    /*color: #00a9aa;*/
    color:#343c60;
  }
  
  #hero .icon-box:hover {
    transform: scale(1.08);
  }
  
  #hero .icon-box:hover .title {
    color: #343c60;
  }
/* Estilos generales del sidebar */
aside {
    height: 100vh;
    min-height: 680px;
    width: 70px;
    left: 0;
    z-index: 1001;
    position: fixed;
    transform: translateX(0);
    background-color: #f2f2f2;
    border-right: 1px solid #e6f2fb;
    transition: 0.7s width cubic-bezier(0.23, 1, 0.32, 1);
    color:#203588;
}

/* Estilos cuando el sidebar está expandido */
.show-sidebar aside {
    width: 260px;
    transform: translateX(0);
}

/* Estilos del logo */
.logo img {
    width: 130px;
    transform: translateX(0);
}
.show-sidebar .logo img {
    width: 130px;
    left: 50%;
    transform: translateX(50%);
}

/* Estilos del botón burger */
aside .toggle {
    padding-left: 0;
    padding-top: 30px;
    position: absolute;
    right: 15px;
    transform: translateX(100%);
    z-index: 1001;
}

/* Sombra cuando el sidebar está expandido */
.show-sidebar aside {
    box-shadow: 10px 0 30px 0 rgba(0, 0, 0, 0.2);
}

/* Estilos internos del sidebar */
aside .side-inner {
    padding-top: 40px;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
}
aside .side-inner::-webkit-scrollbar {
    display: none;
}

aside .side-inner .nav-menu {
    margin-top: 80px;
    margin-bottom: 50px;
    clear: both;
}
.show-sidebar aside .side-inner .nav-menu {
    margin-top: 20px;
    transition: 0s all ease;
}
aside .side-inner .nav-menu ul,
aside .side-inner .nav-menu ul li {
    padding: 0;
    margin: 0;
    list-style: none;
}
aside .side-inner .nav-menu ul li a {
    display: block;
    padding-left: 23px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: inherit;
    position: relative;
    text-decoration: none;
    transition: .3s all ease;
}
aside .side-inner .nav-menu ul li a:hover {
    color: #000;
    background: #f4f4f4;
}
aside .side-inner .nav-menu ul li a .wrap-icon {
    font-size: 1.2rem;
}
aside .side-inner .nav-menu ul li a .menu-text {
    opacity: 0;
    visibility: hidden;
    transition: .3s all ease;
}
.show-sidebar aside .side-inner .nav-menu ul li a .menu-text {
    transition: 0s all ease;
    opacity: 1;
    margin-left: 10px;
    visibility: visible;
}
aside .side-inner .nav-menu ul li.active a {
    color: #000;
}

/* Estilos del botón burger */
.burger {
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: relative;
    z-index: 1001;
    float: right;
    color: #000;
}
.burger:before, .burger span, .burger:after {
    width: 100%;
    height: 2px;
    display: block;
    background: #000;
    border-radius: 3px;
    position: absolute;
    opacity: 1;
}
.burger:before, .burger:after {
    transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
    content: "";
}
.burger:before {
    top: 4px;
}
.burger span {
    top: 11px;
}
.burger:after {
    top: 18px;
}
.burger:hover:before {
    top: 7px;
}
.burger:hover:after {
    top: 21px;
}
.burger.active span {
    opacity: 0;
}
.burger.active:before, .burger.active:after {
    top: 40%;
}
.burger:focus {
    outline: none;
}

/* Estilos para computadoras */
@media (min-width: 992px) {
    aside {
        width: 70px;
    }
    .show-sidebar aside {
        width: 260px;
    }
    .logo img {
        display: none;
    }
    .show-sidebar .logo img {
        display: block;
    }
    aside .side-inner .nav-menu ul li a .menu-text {
        display: none;
    }
    .show-sidebar aside .side-inner .nav-menu ul li a .menu-text {
        display: inline;
    }
    .burger {
        left: -30px;
    }
}

/* Estilos para teléfonos móviles */
@media (max-width: 991px) {
    aside {
        width: 0;
        transform: translateX(-100%);
    }
    .burger {
        display: flex;
        margin-left: 40px; /* Ajuste de margen para asegurar visibilidad */
    }
    .logo img {
        display: block;
    }
    aside .side-inner .nav-menu ul li a .menu-text {
        display: inline;
    }
    .show-sidebar aside .side-inner .nav-menu ul li a .menu-text {
        display: inline;
    }
}

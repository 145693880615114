/* Estilo general para los botones */
.btn-laboratorio {
    background: #35567E;
    color: #fff;
    border: none;
    position: relative;
    height: 9rem;
    width: 9rem;
    font-size: 1.1em;
    padding: 0 2em;
    cursor: pointer;
    transition: 800ms ease all;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
.btn-laboratorio2 {
    background: #d8d8d8;
    color: #0a0a0a;
    border: none;
    position: relative;
    height: 9rem;
    width: 9rem;
    font-size: 1.1em;
    padding: 0 2em;
    cursor: pointer;
    transition: 800ms ease all;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.btn-laboratorio:hover,.btn-laboratorio2:hover {
    background: #d8d8d8;
    color: #35567E;
}

.btn-laboratorio:before,
.btn-laboratorio:after,
.btn-laboratorio2:before,
.btn-laboratorio2:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background: #35567E;
    transition: 400ms ease all;
}

.btn-laboratorio:after,
.btn-laboratorio2:after {
    right: inherit;
    top: inherit;
    left: 0;
    bottom: 0;
}

.btn-laboratorio:hover:before,
.btn-laboratorio:hover:after,
.btn-laboratorio2:hover:before,
.btn-laboratorio2:hover:after {
    width: 100%;
    transition: 800ms ease all;
}

/* Contenedor de los botones */
.d-flex-1 {
    display: flex;
    flex-wrap: wrap; /* Para que los botones se ajusten automáticamente */
    justify-content: center; /* Centra los botones horizontalmente */
}

/* Estilos para pantallas pequeñas (móviles) */
@media only screen and (max-width: 600px) {
    .btn-laboratorio,
    .btn-laboratorio2 {
        width: 100%; /* Los botones ocuparán el 100% del ancho */
        height: 6rem;
        font-size: 1em;
    }
}

/* Estilos para tablets */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .btn-laboratorio,
    .btn-laboratorio2 {
        width: 45%; /* Los botones ocuparán el 45% del ancho */
        height: 8rem;
        font-size: 1.1em;
    }
}

/* Estilos para computadoras */
@media only screen and (min-width: 1025px) {
    .btn-laboratorio,
    .btn-laboratorio2 {
        width: 20%; /* Los botones ocuparán el 20% del ancho */
        height: 9rem;
        font-size: 1.2em;
    }
}




.tema-foro{
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
}
.tema-card{
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    box-shadow: 3px 1px 6px 1px rgba(156,153,156,1);
    margin: 30px 10px;
}
.respuesta-card{
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    margin-bottom: 10px;
}
.respuesta-btn{
    border: none;
    background: none;
    font-size: 1.1rem;
    font-weight: bolder;
}
.respuesta-btn:hover{
    color: rgb(99, 96, 96);
}

.texto-foro-clase{
    position: absolute;
    top:-10px;
    left: 5PX;
    font-size: 0.75rem;
    color: #999;
    background-color: #FFF;
    padding: 0 5px;
    margin-left: 5px;
    width: 110px;

}
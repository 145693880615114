@keyframes blink {
  10% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.btn-11 {
  position: relative;
  border:#1AAB8A solid 1px;
  color:#fff;
  height: 80px;
  border-radius: 3px;
  background:#1AAB8A;
  font-size:1.6em;
  padding:0 2em;
  cursor:pointer;
  transition:800ms ease all;
  outline:none;
  /*transition: color 0.3s ease;*/
}
.btn-11:hover{
  background:#fff;
  color:#1AAB8A; 
  border:none;
  /*animation: blink 1s infinite;*/
  
}
.btn-11:before,.btn-11:after{
  content:'';
  position:absolute;
  top:0;
  right:0;
  height:2px;
  width:0;
  background: #1AAB8A;
  transition:400ms ease all;
}
.btn-11:after{
  right:inherit;
  top:inherit;
  left:0;
  bottom:0;
}
.btn-11:hover:before,.btn-11:hover:after{
  width:100%;
  transition:800ms ease all;
}

.area_textos{
  height: 20rem;

}
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Rajdhani:wght@300;400;500;600;700&display=swap');

.principal-card{
    position:relative;
    background-color: #f7f7f7;
    margin:15px;
    border-radius: 15px 15px 15px ;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    height: 180px;
}
.principal-card-titulo{
    display: flex;
    justify-content: center;
    background-color:#d62C31;;
    color: #fff;
    font-size: 1.5rem;
    height: 50px;
    padding-top: 10px;
    border-radius: 15px 15px 0px 0px;
}
.principal-caja-circulo-logo{
    position:absolute;
    left:-20px;
    top:-8px;
    width:60px;
    height: 60px;
    border-radius: 50%;
    background-color: #35567E;
    color:#fff;
    z-index: 1000;
}
.principal-caja-borde-logo{
    position:absolute;
    z-index: 900;
    background: rgb(53,86,126);
    background: linear-gradient(0deg, rgba(53,86,126,1) 0%, rgba(53,86,126,1) 55%, rgba(16,25,37,1) 100%);
    height: 180px;
    width: 20px;
    border-radius: 0 0 0 15px;
}
.principal-caja-circulo-logo i{
    padding-top: 16px;
    padding-left: 14px;
    font-size: 25px;
}

.principal-texto-info-rojo{
    font-family: "Rajdhani", sans-serif;
    font-weight:bolder;
    font-style: normal;
    font-size: 6rem;
    color: #d62C31;
}
.cuadro-info-simple{
    display: flex;
    justify-content: center;
    width: 15rem;
    margin: 10px;
    margin-right: 25px;
    border-radius: 20px;
    padding: 20px 0;
    box-shadow: 1px 1px 9px 1px rgba(153,153,153,1);
}
.cuadro-fondo1{
    background: rgb(53,86,126);
    background: linear-gradient(266deg, rgba(53,86,126,1) 0%, rgba(214,44,49,1) 100%, rgba(216,216,216,1) 100%);
}
.cuadro-fondo2{
    background: rgb(53,86,126);
    background: linear-gradient(90deg, rgba(53,86,126,1) 0%, rgba(214,44,49,1) 100%, rgba(216,216,216,1) 100%);
}
.cuadro-fondo3{
    background: rgb(53,86,126);
    background: linear-gradient(90deg, rgba(53,86,126,1) 0%, rgba(214,44,49,1) 100%, rgba(216,216,216,1) 100%);
}
.cuadro-fondo4{
    background: rgb(53,86,126);
    background: linear-gradient(90deg, rgba(53,86,126,1) 0%, rgba(214,44,49,1) 100%, rgba(216,216,216,1) 100%);
}
.cuadro-info-simple i{
    position: relative;
    top: 5px;
    color:#fff;
    font-size: 1.rem;
    margin-right: 5px;
    display: flex;
    justify-content: center;

}

.texto-info1{
    display: flex;
    justify-content: center;
    font-family: "Rajdhani", sans-serif;
    font-size: 1.1rem; 
    font-weight:700;
    color:#fff;
}
@media screen {
    
}
.principal-grafico{
    background-color: #f7f7f7;
    border: #d8d8d8 solid 1px; 
    box-shadow: 1px 1px 8px 1px rgba(153,153,153,1);
    margin-top:20px;
}

/* clases ESTUDIANTE*/
.principal-estudiante-card-superior{
   background-color : #000;
   width: 100%;
padding: 20px;
}
.contenedor-principal-nombre{
    align-items: center;
    margin-left: 10px;
    
}
.principal-nombre {
    color: #fff;
    position: relative;
    top:80px;
}
.pie-foto{
    display: flex;
    justify-content: center;
    color: #fff;
    
}
.principal-estudiante-textoMenu{
    font-size: 1.2rem;
    color: #fff;
    margin: 0 0.5rem;
    text-decoration: none;
    font-weight:500;
}
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.contenedor-card-estructura{
    background-color: #fff;
    width:100%;
    display:flex;
    flex-wrap:wrap;
    justify-content:left;
}
.contenedor-form{
    width:90%;
    text-align:center;
    border: #e4e1e1 1px solid;
    border-radius: 5px;
    margin:20px;
    padding:20px;

}
.imagen-caratula{
    border-radius: 5px;
}
.curso-card{
    border: 1px solid #1e0a79;

}
/*Formacion */
.fuente-formacion{
    font-family: "Montserrat", sans-serif;
}
.formacion-texto-nivel{
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
}
.formacion-texto-sin_resultados{
    color: #8f8f8f;
}
.card-estructura{
    position:relative;
    border: 1px solid #999;
    margin:15px;
    padding:10px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    width: 18rem;
    height: 25rem;
    overflow: hidden;
    font-family: "Montserrat", sans-serif;
}
.caja-imagen-top{
    position:absolute;
    left:0;
    top:0;
    width: 100%;
    background-color: rgb(255, 255, 255);

    border-radius: 5px 5px 0 0;
}
.caja-deco-top{
    position:absolute;
    left:0;
    top:0;
    width: 100%;
    background-color: rgb(255, 255, 255);
    height: 2rem;
    border-radius: 5px 5px 0 0;
}
.caja-cuerpo{
    position: absolute;
    top:12rem;
    left:0;
    width: 100%;
    padding-left:1rem;
    padding-right:1rem;
    background-color: #fff;
}
.caja-circulo-logo{
    position:absolute;
    left:-20px;
    top:-20px;
    width:50px;
    height: 50px;
    border: 2px solid #999;
    border-radius: 50%;
    background-color: rgb(44, 103, 180);
    color:#fff;
}
.caja-circulo-logo i{
    padding-top: 12px;
    padding-left: 12px;
    font-size: 20px;
}
.caja-titulo{
    font-family: "Montserrat", sans-serif;
    margin-left: 20px;
    color:#666;
}
.caja-cantidades{
    margin-left: 20px;
    color:#666;
}
.caja-cantidades span{
    font-size: 20px;
    font-weight: bold;
}
.caja-medio{
    font-size: 12px;
    font-weight: bold;
    margin: 0 auto;
}
.caja-botonera{
    margin: auto;
    color:#666;
}
.caja-info{
    width: 100%;
    color:#666;
    font-size: 10px;
    font-weight: bold;
}
.caja-deco-top{
    position:absolute;
    left:0;
    top:0;
    width: 100%;
    background-color: aqua;
    height: 2rem;
    border-radius: 5px 5px 0 0;
}
.docentes{
    border-bottom: #666 solid 1px;
}
.contenidoScroleable {
    width: 100%; /* Ancho fijo de 300px */
    height: 180px; /* Altura deseada */
    overflow-y: auto; /* Añadir scroll vertical cuando sea necesario */
    /* Añade cualquier otro estilo deseado */
    margin-bottom: 10px;
    margin-left: 10px;

}

.caja-cuerpo2{
    position: absolute;
    top:10rem;
    left:0;
    width: 100%;
    padding-top: 1rem;
    padding-left:1rem;
    padding-right:1rem;
    background-color: #fff;
}
.card-text{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    padding-left:1rem;
    padding-right:1rem;
    background-color: #fff;
    align-items: center;
    font-weight: bold;
}

.btn-sigma1{
    padding: 12px ;
    background-color: #fd2828;
    border: 1px solid #343c60;
    width: 100%;
    border-radius: 5px;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    margin-top: 5px; 
}
.btn-sigma1:hover {
      border: 1px solid #fd2828;
      background-color: #fff;
      color: #fd2828; 
}

.btn-csi{
    padding: 8px 10px ;
    background-color: #fd2828;
    border: 1px solid #343c60;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: #fff;
    margin-top: 5px; 
}
.btn-csi:hover {
      border: 1px solid #fd2828;
      background-color: #fff;
      color: #fd2828; 
}

/* estilo de clases*/
.hoja{
    margin: 3px;
    padding:20px;
    padding-right: 30px;
	border:1px solid #E3E2E2;
	-webkit-box-shadow: 2px 1px 9px -4px rgba(0,0,0,0.75);
	-moz-box-shadow: 2px 1px 9px -4px rgba(0,0,0,0.75);
	box-shadow: 2px 1px 9px -4px rgba(0,0,0,0.75);
}

.hoja-form{
    margin-top:5px;
    padding:20px;
	border:1px solid #E3E2E2;
	-webkit-box-shadow: 2px 1px 9px -4px rgba(0,0,0,0.75);
	-moz-box-shadow: 2px 1px 9px -4px rgba(0,0,0,0.75);
	box-shadow: 2px 1px 9px -4px rgba(0,0,0,0.75);
}

.form-clase{
    padding-right: 40px;
}

.informacion img{
    width: 100%;
    object-fit: cover;
}
.clase-titulo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid #7a7a7a;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .elementos_clase {
    display: flex;
    align-items: center;
    gap: 10px; /* Espaciado entre los iconos, ajusta según sea necesario */
    font-size: 1.1rem;
    color: #5a5757;
  }

/*Lista de clases*/
.lista-clases{
    position: relative;
    margin:0;
    padding:0;
    margin-top: 10px;
    list-style-type: none;
    left:-30px;
   
}
.item-clase{
    margin-bottom: 5px;    
    width: 100%;
    padding: 3px;
    padding-left:30px;
}
.item-clase-texto{
    color:#1e0a79;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    margin:0; 
     
}
.item-clase:hover{
    background-color: #f2f2f2;
    border-radius: 0 20px 20px 0;
}
.item-clase-texto i{
    color:#999;
}
.item-clase-texto.activa, .item-clase-texto.activa:hover {
    color:#7a7a7a;
    font-weight:100;
}
.item-clase-texto-disabled{
    color:#c9c9c9;
    font-size: 1rem;
    font-weight:100;
    margin:0;
}
.item-clase-texto-disabled i{
    color:#c9c9c9;
}

/* material de clase*/
.clase-material{
    position: relative;
    width: 98%;
    padding: 10px;
    padding-top: 18px;
    padding-left: 15px;
    border-top: 1px solid #343c60;
    border-radius: 0 10px 10px 0 ;
    margin-top:20px;
    margin-left: 10px;
    margin-bottom: 20px;
}
.clase-material-titulo{
    position: relative;
    top:-15px;
    left:-15px;
}
.clase-material-titulo .clase-material-circulo{
    position: absolute;
    left: -20px;
    top:-20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color:#ffffff;
    border:1px solid #343c60;
    background-color: #343c60;
}
.clase-material-circulo i{
    position: absolute;
    font-size: 20px;
    top:50%;
    left:50%;
    margin: 0;
    transform: translate(-50%, -50%);
}
.clase-material-titulo div{
    position: absolute;
    left: 0;
    top:-10px;
    width: 90px;
    height: 20px;
    font-size: 13px;
    background-color: #343c60;
    border-radius: 0 20px 20px 0;
    color: #fff;
    padding-left: 20px;
}
.clase-boton-material{
    position: absolute;
    top:-15px;
    right: 0;
    border:#343c60 1px solid;
    background-color:#343c60 ;
    border-radius: 5px;
    color:#fff;
    padding:5px;
    font-size: .8rem;
   
}
.clase-boton-material:hover{
    border:#4a5588 1px solid;
    background-color:#4a5588 ;
    border-radius: 5px;
    padding:5px;
    cursor: pointer;}
.clase-boton-material-cancel{
    position: absolute;
    top:-15px;
    right: 0;
    border:#4d4d4d 1px solid;
    background-color: #797878;
    border-radius: 5px;
    color:#fff;
    padding:5px;
    font-size: .8rem;}
.clase-boton-material-cancel:hover{
    border:#4d4d4d 1px solid;
    background-color: #585858;
    border-radius: 5px;
    padding:5px;
    cursor: pointer;
}
.clase-material-titulo span{
    position: absolute;
    font-size: 20px;
    top:50%;
    left:50%;
    margin: 0;
    transform: translate(-50%, -50%);
}
.material-clase-link{
    font-size: .9rem;
    border:1px solid #a9cdce;
    background-color: #f1fbfb;
    border-radius: 20px 20px 5px 20px;
    padding: 8px;
    padding-left: 10px;
    margin:4px 0;
}
.material-clase-link >i{
    font-size: 1.1rem;
    margin-right: 5px;
    margin-left: 10px;
}
.material-clase-link .fa-file-word{color:#067ece;}
.material-clase-link .fa-file-pdf{color:#ce0606;}
.material-clase-link .fa-file-excel{color:#05a80d;}
.material-clase-link .fa-link{ font-size: .9rem; color:#06b9b9;}

.material-form-selector{
    text-align: center;
}
.material-form-selector label{
    text-align: center;
    font-size: .9rem;
}
.texto-item{
    text-decoration: none;
    color: #4d4d4d;
}
/*actividades de clase*/
.clase-actividad{
    position: relative;
    width: 98%;
    padding: 10px;
    padding-top: 18px;
    padding-left: 15px;
    border-top: 1px solid #606060;
    margin-top:30px;
    margin-left: 10px;
    margin-bottom: 20px;
}
.clase-actividad-titulo{
    position: relative;
    top:-18px;
    left:-15px;
}
.clase-actividad-titulo .clase-actividad-circulo{
    position: absolute;
    left: -20px;
    top:-22px;
    width: 40px;
    height: 40px;
    border: 1px solid #606060;
    border-radius: 10px;
    color:#fff;
    background-color:#606060;
}
.clase-actividad-circulo i{
    position: absolute;
    font-size: 20px;
    top:50%;
    left:50%;
    margin: 0;
    transform: translate(-50%, -50%);
}
.clase-actividad-titulo div{
    position: absolute;
    left: 0;
    top:-10px;
    width: 90px;
    height: 20px;
    font-size: 13px;
    background-color: #606060;
    border-radius: 0 20px 20px 0;
    color: #fff;
    padding-left: 20px;  
}

.actividad-clase-link{
    font-size: .9rem;
    border:1px solid #ffc559;
    background-color: #ffe6b7;
    border-radius: 20px 20px 0 20px;
    padding: 8px;
    padding-left: 10px;
    margin:4px 0;
}
.actividad-clase-link .icono{
    font-size: 3rem;
    color:  #ffa34d;;

}


.clase-boton-actividad{
    position: absolute;
    top:-15px;
    right: 0;
    border:#fd2828 1px solid;
    background-color:#fd2828;
    border-radius: 5px;
    color:#fff;
    padding:5px;
    font-size: .8rem;
}
.clase-boton-actividad:hover{
    border:#fd2828 1px solid;
    background-color:#e94a4a;
    border-radius: 5px;
    padding:5px;
    cursor: pointer;
}
.clase-boton-actividad-cancel{
    position: absolute;
    top:-15px;
    right: 0;
    border:#4d4d4d 1px solid;
    background-color: #797878;
    border-radius: 5px;
    color:#fff;
    padding:5px;
    font-size: .8rem;}
.clase-boton-actividad-cancel:hover{
    border:#4d4d4d 1px solid;
    background-color: #585858;
    border-radius: 5px;
    padding:5px;
    cursor: pointer;
}
ul.square {list-style-type: square;}

.ql-size-huge {
    font-size: 32px; /* Ajusta el tamaño según sea necesario */
}

.ql-size-small {
    font-size: 10px;
}

.ql-size-large {
    font-size: 18px;
}

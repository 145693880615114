@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');

.fondo-inscripciones{
/*  background: rgb(15,103,58);
    background: radial-gradient(circle, rgba(15,103,58,1) 0%, rgba(52,152,100,1) 45%, rgba(74,228,148,1) 100%);

    background: rgb(154,193,173);
    background: radial-gradient(circle, rgba(154,193,173,1) 0%, rgba(134,244,187,1) 45%, rgba(237,240,238,1) 100%);

    background: rgb(250,214,249);
    background: linear-gradient(180deg, rgba(250,214,249,1) 0%, rgba(226,216,232,1) 49%, rgba(167,170,240,1) 100%);
  */
    background: rgb(52,60,96);
    background: linear-gradient(59deg, rgba(52,60,96,1) 0%, rgba(255,255,255,1) 49%, rgba(230,29,39,1) 100%);
    width: 100vw;
    min-height: 100vh;
    
}
.fondo-inscripciones.titulo-inscripcion{
    font-family: "Epilogue", sans-serif;
    font-optical-sizing: auto;
    font-weight:800;
    font-style: normal;  
}

.btn-original1{
    width: 90%;
    /*background:#1AAB8A;*/
    background:#343c60;
    color:#fff;
    border:none;
    position:relative;
    height:100px;
    font-size:1.7em;
    padding:0 2em;
    cursor:pointer;
    transition:800ms ease all;
    outline:none;
  }
  .btn-original1:hover{
    background:#fff;
    color:#e61d27;
  }
  .btn-original1:before,.btn-original1:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    height:2px;
    width:0;
    background: #343c60;
    transition:400ms ease all;
  }
  .btn-original1:after{
    right:inherit;
    top:inherit;
    left:0;
    bottom:0;
  }
  .btn-original1:hover:before,.btn-original1:hover:after{
    width:100%;
    transition:800ms ease all;
  }
  
  h1{
    text-align: center;
    font-weight: 700;
    color: #000;
  }
@media screen and (max-width: 825px) {
    .btn-original1{
        font-size:1.40em;
    }
}
.fondo-inscripciones form{
    background-color: #fff;
    padding: 40px 0;
    box-shadow: 0 0 6px 0 rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    margin: 50px;
  }
.form{
    width: 100%;
    margin: auto;
  }
.form .grupo{
    position: relative;
    margin: 45px;
  }
.form input{
    background: none;
    color:#c6c6c6;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border:none;
    border-bottom: 1px solid #868686;
  }
.form input:focus{
    outline: none;
    color:#868686;
  }
.form label{
    color: #868686;
    font-size: 16px;
    position: absolute;
    top: 10px;
    left: 10px;
    transition: 0.5s ease all;
    pointer-events: none;
  }

.form input:focus~label,
.form input:valid~label{
    top:-14px;
    font-size: 12px;
    color: #2196f3;
  }
.form .barra{
    position: relative;
    display: block;
    width: 100%;
}
.form .barra::before{
    content: "";
    height: 2px;
    width: 0%;
    bottom: 0;
    position: absolute;
    background: linear-gradient(to right, #806AB3, #4568DC);
    transition: 0.3s ease width;
    left: 0;
}

.form input:focus~.barra::before{
    width: 100%;
}
/* mensajes */
.mensaje-row{
    cursor:pointer;
}
.mensaje-textarea{
    height: 300px;
}
.contenedor-adjuntos{
    margin:5px;
}
.adjunto{
    padding: 5px;
    border: #666 solid 1px;
    border-radius: 10%;
    color:#999;
    margin:3px;
}

.mensaje-de{
    font-size: 1.2rem;
    font-weight: bold;
}
.mensaje-de i{
    font-size: 0.9rem;
    color: #999
}

.mensaje-de-usuario{
    font-size: 0.9rem;
}
.mensaje-fecha{
    font-size: 0.7rem;
    color:#666;
}
.mensaje-para{
    font-size: 0.7rem;
    color:#666;
}
.lista-sugerencias{
  background-color: #ffffff;
  box-shadow: 2px 2px 5px;
  border-radius: 0 0 10px 10px;
  padding-bottom: 20px;
}

.lista-sugerencias li{
  list-style-type: none;
  padding-top:10px;
}
.lista-sugerencias li:hover{
  color: #00f;
  cursor: pointer;
}
.respuestas{
  border-left: #000 2px solid;
}
.mensaje-original{
  font-size: 1.3rem;
}
.mensaje-respuesta{
  font-size: 0.9rem;
}
/* calendario */
.calendario-container {
    width: 100%;
}
.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  
  .calendar-day {
    border: 1px solid #ccc;
    padding: 10px;
    min-height: 100px;
    position: relative;
  }
  .calendar-day:hover {
    border: 1px solid #484848;
  }
  .calendar-day span {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 0.8rem;
  }
 
  .evento-pasado {
    background-color: #e8e2b4;
    color: #a0a0a0;
    padding: 5px;
    margin-top: 5px;
    border-radius: 3px;
    box-shadow: 2px 2px 5px;
    font-size: 0.78rem;
  }
  .evento-pasado:hover{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
  .evento {
    background-color: #ffeb3b;
    padding: 5px;
    margin-top: 5px;
    border-radius: 3px;
    box-shadow: 2px 2px 5px;
    font-size: 0.78rem;
  }
  .evento:hover{
    position: relative;
    width: 120%;
    height: 100%;
    z-index: 999;
    
  }
 
  .todosA{
    background-color: #3be2ff;
    color: #000;
  }
  .todosD{
    background-color: #3bff86;
    color: #000;
  }
  .todosE{
    background-color: #fab650;
    color: #000;
  }
  .todosT{
    background-color: #d0b7ff;
    color: #000;
  }
  .todosM{
    background-color: #0f1d9c;
    color: #fff;
  }

  .dia-anterior {
    background-color: #f5f5f5;
  }
  
  .mes-diferente span {
    color: #999;
  }
.calendario-titulo{
    font-weight: bolder;
    font-stretch: expanded;
    display: flex;
    justify-content: center;
}

.hoy {
    background-color: #9ed6bd;
  }
  
.btn-elim-evento{
  position: relative;
  right: 0;
  z-index: 1000;
  cursor: pointer;
}

.react-calendar {
    width: 100%;
    max-width: 100%;
}

.react-calendar__tile {
    padding: 10px;
    height: 80px; /* Ajusta la altura de cada celda según sea necesario */
}

.react-calendar__tile--now {
    background: #f0f8ff;
    border: 1px solid #00f;
}
.btn-agenda{
  font-size: 0.5rem;
  position: relative;
  right: -10px;
  top: -10px;
}


.estudiantes-titulo{
    color: #343c60;
    padding-bottom: 20px;
    margin-right:20px;
    border-bottom:#343c60 solid 1px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.contenedor-estudiante{
    position:relative;
    width:100%; 
    margin: 0 auto;
    margin-right: 5px; 
    margin-bottom: 5px;
    padding-top: 3px;
    padding-bottom: 2px;
    border-bottom: #e9e9e9 solid 1px;
}
.estudiantes-nombre{
    color: #474747;
    font-size: 13px;
}
.estudiantes-nombre2{
    color: #a9a9a9;
    font-size: 13px;
}
.estudiante-botonera{
    text-align: end;
}
.estuidante-input-valoracion{
    width: 2rem;
    border: none;
    border-bottom: 0.08rem solid #646464;
}
.estudiante-select-valorativa{
   color:rgb(0, 0, 0);
}
.estudiante-select-numerica{
    color:rgb(0, 0, 0);
}
.estudiante-calificado{
    color:rgb(0, 0, 0);
    font-weight: bolder;
}
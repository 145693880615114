
.container-usuarios{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    justify-content:left;
}

.card-principal{
    position: relative;
    padding:2px;
    width: 400px;
    height: 1000px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border: 3px solid #d62c31;
    font-family: sans-serif;
    font-size: 1.3rem;
    border-radius: 10px;
    overflow: hidden;
}
.card-superior{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 150px;
    background-color: #35567E; 
    text-align: center;
    }
    .card-nombre{
        font-weight: bold;
        margin-top: 20px;
        color:#fff;
    }
    .imagen-circular{
        width: 10rem;
        height: 10rem;
        position: relative;
        z-index:900;
        border-radius: 50%;
        border:5px solid #fff;
        background-color: #fff;
    }

  

.card-cuerpo{
    position: absolute;
    padding-top: 60px;
    top:150px;
    left:0;
    width: 100%;
    z-index:800;
    text-align: center;
    color:#35567E;
    background-color: #fff;
    }
    .card-btn-editar{
        position: absolute;
        top:10px;
        left:10px;
        cursor: pointer;
        color:#35567E;
        padding:5px;
        font-size: 0.8rem;
    }
    .card-btn-editar:hover{
        color:#d62c31;
        border-radius: 5px;
        border: 1px solid #d62c31;
    }
    .card-apodo{
        font-size: 0.8rem;
        color:#999;
        padding-bottom: 10px;
    } 
    .card-info-fija{
        padding: 10px;
        font-size: 0.8rem;
        text-align: left;
    }
    .card-info-edita{
        position: absolute;
        z-index:1000;
        top: 65px;;
        left: 0;
        z-index:1000;
        padding:0 10px;
        text-align: left;
        font-size: 0.8rem;
    }
    .form-perfil div{
        text-align: center;
    }
    .form-perfil input[type="file"]{
        display: none;
      }
      
    .form-perfil .label-imagen{
        margin-bottom: 4px;
        background: #35567E;
        color: white;
        padding: 4px 20px;
        cursor: pointer;
        border-radius: 3px;
      }

 /* perfil logo */
 .contenedor-logoPerfil {
    display: flex;
    align-items: center;
}
.iniciales {
    background-color: #d62c31;
    color: #fff;
    border-radius: 5px;
    padding: 0.5rem;
    font-size: 1rem;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.imgCh {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    object-fit: cover;
}
.nombreApellidox14{
    font-size: 1rem;
    margin-left: 0.5rem;
}
.nombreApellido {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
}

.apodoEdad {
    font-size: 0.75rem;
    color: #666;
}
.logo_perfil{
    width: 40px;
    height: 40px;
    position: relative;
    top:7.5rem;
    left:-3.5rem;
    z-index: 1000;
}
/*fin perfil logo*/
.form-control {
    border: none;
    border-bottom: 1px solid #e7e7e7;
    border-radius: 0;
    padding: 9px 5px;
    min-height: 40px;
    font-size: 20px;
    font-weight: normal; 
}
.form-control::-webkit-input-placeholder {
    color: #b0adad; 
}
.form-control::-moz-placeholder {
    color: #b0adad; 
}
.form-control:-ms-input-placeholder {
    color: #b0adad; 
}
.form-control::-ms-input-placeholder {
    color: #b0adad; 
}
.form-control::placeholder {
    color: #b0adad; 
}
@media (max-width: 575px) {
    .img-fluid{
        width: 60%;
    }
}
.texto_error{
    font-size: 1.2rem;
    color: #ff0000;
    font-weight: bold;
}

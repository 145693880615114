.footer{
    margin-top: 0.9rem;
    background-color: #f2f2f2;
    padding: 5px;
    text-align: center;
    height: 12rem;
}
.footer-logo{
    width: 6rem;
    align-items:start;
}
.footer-text{
   margin-top: 1.9rem;
    font-size: 0.7rem;
    font-weight: bolder;
}
#header {
    background-color: #fff;
    border-bottom: 1px solid #e6f2fb;
    box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
    z-index: 1000;
    color: #fff; /* Color heredado */
    padding-left: 80px;
}

#header .logo-texto {
    font-size: 1.5rem;
    font-weight: bold;
    color: inherit; /* Hereda el color del elemento padre #header */
}
#header .logo1 img {
    height: 40px;
}

#header .wrap-icon {
    font-size: 1.3rem;
    padding: 0.5rem 0;
    color: inherit; /* Hereda el color del elemento padre #header */
}

/* Asegurar herencia de color en Navbar */
#header .navbar, 
#header .navbar * {
    color: inherit; /* Hereda el color del elemento padre #header */
}

/* Especificar color negro para NavDropdown.Item */
#header .navbar .nav-dropdown-item {
    color: #000 !important; /* Sobrescribir el color heredado y fijarlo a negro */
}



.navbar {
    padding: 0.5rem 1rem;
}

.navbar-brand {
    display: flex;
    align-items: center;
}

.align-derecha {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.nav-link, .navbar-nav .nav-link {
    padding-right: 1rem;
}

.navbar-nav .nav-item {
    display: flex;
    align-items: center;
}

.navbar-nav .nav-item .dropdown-toggle {
    display: flex;
    align-items: center;
}
.info-mensajes-head{
    position: relative;
    top: 3px;
    left: -4px;
    background-color: #e70404;
    color:#fff;
    font-size: 0.78rem;
    border-radius: 50%;
    padding: 0 5px 0 5px;
    margin-right: 5px;
}
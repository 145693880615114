.contenedor-micurso{
    margin: 0 15px 0 0;
}
.portada-curso{
    position: relative;
    width: 100%;
    height: 230px;
    border: 1px solid rgb(52, 60, 96);
    border-radius: 10px 10px 0 0;
    overflow:hidden;
    margin: 0;
}
.portada-curso img {
    position: absolute;
    top:-10px;
    left:0;
}
.portada-curso h3{
    position:absolute;
    top:0;
    left:0;
    z-index: 900;
    font-size: 1.7rem;
    width: 100%;
    height: 2.2rem;
    padding-left: 20px;
    background-color: rgb(52, 60, 96, .8);
    color: #fff;
}
.portada-curso h4{
    position:absolute;
    top:2.2rem;
    left:0;
    z-index: 900;
    font-size: 1rem;
    width: 100%;
    height: 1.3rem;
    padding-left: 20px;
    background-color: rgb(52, 60, 96, .8);
    color: #fff;
}
.portada-curso h5{
    position:absolute;
    top:2.2rem;
    right: 10px;
    z-index: 901;
    font-size: 1rem;
    height: 1.3rem;
    padding-left: 20px;
    color: #fff;
}
.btn-csi2{
    padding: 8px 10px ;
    width: 100%;
    border-radius: 0 0 6px 6px;
    font-size: 14px;
    font-weight: bold;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-top: 1px; 
    background-color: #fff;
    border: 1px solid #343c60;
    color: #343c60; 
}

.btn-csi2:hover {
    background-color: #343c60;
    border: 1px solid #fd2828;
    color: #fff;
}
.contenedor-form-cohorte{
    display: flex;
    justify-content: center;
}
.form-cohorte{
    width: 600px;
    border: #e2e2e2 solid 1px;
    border-radius: 5px;
    padding: 20px;
    
}
@media screen and (max-width: 992px) {
    .form-cohorte{
        width: 70%;
    }
}
@media screen and (max-width: 600px) {
    .form-cohorte{
        width: 100%;
        padding: 10px;
    }
}
.contenedor-instancias{
    border: #e2e2e2 solid 1px;
    border-radius: 5px;
    padding: 8px;
}
.texto-resultado{
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.texto-resultado-nombre{
    font-size: 1.2rem;
    font-weight:bolder;
}
.titulo-cursos{
    padding-bottom: 5px;
    border-bottom: 0.1rem solid #e6e6e6;
    margin-bottom: 15px;
}
.small1{
    font-size: 0.8rem;
}
.hacer-link{
    cursor: pointer;
}